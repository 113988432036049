import t from 'react-translate';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { DeepPartial } from 'utility-types';

import { RootState } from 'redux/schemas';
import { useAppDispatch } from 'redux/store';
import NvFroala from 'froala/components/nv-froala';
import { AngularServicesContext } from 'react-app';
import { getCurrentUser } from 'redux/selectors/users';
import { gray2, gray5, gray6 } from 'styles/global_defaults/colors';
import NvProfileCard from 'shared/components/nv-profile-card';
import { TextAlign } from 'shared/components/nv-tooltip';
import { FroalaViewMode } from 'froala/helpers/nv-froala-constants';
import ActivityTitle from 'shared/components/activity/activity-title';
import ActivityPoints from 'shared/components/activity/activity-points';
import ActivityStatus from 'shared/components/activity/activity-status';
import { ProfileRequirement } from 'redux/schemas/models/profile-requirement';
import { getPointsConfiguration } from 'redux/selectors/points-configurations';
import { getCurrentCourse } from 'redux/selectors/course';
import { LectureComponentProps, LecturePageMode } from 'lecture_pages/components';
import { getIsActivity, getLectureComponent } from 'redux/selectors/lecture-components';
import { ComponentType, LectureComponent, NLectureComponent } from 'redux/schemas/models/lecture-component';
import LectureComponentHeaderInput from 'shared/components/lecture-component-header-input';
import BaseLectureComponentContext from 'lecture_pages/directives/components/base-lecture-component/context';
import { halfSpacing, largeSpacing, doubleSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import {
  getProfileRequirement,
  createGetProfileRequirementOrgFields,
  createGetProfileRequirementCourseProfileQuestions,
} from 'redux/selectors/profile-requirements';
import React from 'react';
import { useLecturePageParams } from 'lecture_pages/hooks/lecture-routing';
import { MyAccount } from 'redux/schemas/models/my-account';
import { updateLectureComponent } from 'redux/actions/lecture-pages';
import config from '../../../config/config.json';

type ProfileCompletionLectureComponentType = LectureComponent<ComponentType.PROFILE_COMPLETION>;

/**
 * This type is for any use of saveComponent function, to make user pass only
 * properties that wants to replace from lecture component (see handleTitleBlur
 * event handler).
 */
type ComponentPatch = DeepPartial<ProfileCompletionLectureComponentType>;

const getProfileRequirementCourseProfileQuestions = createGetProfileRequirementCourseProfileQuestions();

const getProfileRequirementOrgFields = createGetProfileRequirementOrgFields();


/**
 * This lecture component allows courses to enforce learners to fill their
 * profile if has required questions configured, it displays a button to open
 * learner profile modal and start completing the user's profile
 */
const ProfileCompletionLectureComponent = (props: LectureComponentProps<ComponentType.PROFILE_COMPLETION>) => {
  const params = useLecturePageParams();
  const currentUser = useSelector<RootState, MyAccount>(getCurrentUser);
  const catalogId = useSelector((state) => state.app.currentCatalogId);

  const lectureComponent = useSelector((state) => (
    getLectureComponent(state, props.lectureComponent.id)
  )) as NLectureComponent<ComponentType.PROFILE_COMPLETION>;

  const profileRequirement = useSelector((state) => (
    getProfileRequirement(state, lectureComponent.profileRequirement)
  ));

  const pointsConfiguration = useSelector((state) => (
    getPointsConfiguration(state, profileRequirement.pointsConfigurationId || 0)
  ));

  const orgFields = useSelector((state) => (
    getProfileRequirementOrgFields(state, lectureComponent.profileRequirement)
  ));

  const courseProfileQuestions = useSelector((state) => (
    getProfileRequirementCourseProfileQuestions(state, lectureComponent.profileRequirement)
  ));

  const currentCourse = useSelector(getCurrentCourse);

  const dispatch = useAppDispatch();
  const isEditMode = props.mode === LecturePageMode.EDIT;
  const isNotViewMode = props.mode !== LecturePageMode.VIEW;
  const [title, setTitle] = React.useState<string>(profileRequirement.title);
  const { $state } = React.useContext(AngularServicesContext);

  const { sharedProps, setSharedProps } = React.useContext(BaseLectureComponentContext);
  const [froalaContent, setFroalaContent] = React.useState<string>(lectureComponent.content);

  const isActivity = useSelector((state) => getIsActivity(state, props.lectureComponent.id));

  const saveComponent = React.useCallback((componentDataPatch: ComponentPatch) => {
    const componentDataUpdate = {
      catalogId: params.catalogId,
      lecturePageId: props.currentLecture.id,
      componentData: {
        id: lectureComponent.id,
        type: lectureComponent.type,
        index: lectureComponent.index,
        content: lectureComponent.content,
        ...componentDataPatch,
        profileRequirement: {
          title: profileRequirement.title,
          isTodo: profileRequirement.isTodo,
          requiredNovoedQuestions: profileRequirement.novoedQuestions,
          requiredOrgFieldIds: orgFields.filter(
            (field) => field.isRequired,
          ).map((field) => field.id),
          requiredCourseProfileQuestionIds: courseProfileQuestions.filter(
            (courseProfileQuestion) => courseProfileQuestion.isRequired,
          ).map((courseProfileQuestion) => courseProfileQuestion.id),
          ...((componentDataPatch.profileRequirement || {}) as ProfileRequirement),
        },
      },
    };

    // TODO: This is broken; updateLectureComponent was changed to accept a normalized lecture component,
    // but this API expects non-normalized data. I think all lecture component saves should probably use
    // non-normed data; let's revisit when we work on this component
    return dispatch(updateLectureComponent(componentDataUpdate as any));
  }, [lectureComponent, profileRequirement]);

  const getTitleInputWrapperStyles = () => {
    if (!isActivity || (isActivity && !pointsConfiguration)) {
      return css`
        margin-bottom: ${halfSpacing}px;
      `;
    }
    return '';
  };

  const styles = css`
    display: flex;
    align-items: center;
    flex-direction: column;
    border-bottom: 1px solid ${gray5};
    padding-bottom: ${doubleSpacing}px;

    .activity-status {
      margin-bottom: ${halfSpacing}px;
    }

    .title-input-wrapper {
      width: 100%;
      ${getTitleInputWrapperStyles()};
    }

    .activity-points {
      margin-bottom: ${standardSpacing}px;
    }

    .expectations-wrapper {
      width: 100%;
      margin-bottom: ${largeSpacing}px;
    }

    .cta {
      text-align: center;

      .note {
        color: ${gray2};
        margin-bottom: ${halfSpacing}px;
      }
    }

    .collection-warning {
      background-color: ${gray6};
      width: 100%;
      height: 100px;
    }
  `;

  const openLearnerModal = () => {
    $state.go('learner-profile-modal', {
      userId: currentUser.id,
      catalogId,
    });
  };

  const handleTitleInputChange = (newTitle: string) => setTitle(newTitle);

  const handleTitleBlur = () => saveComponent({
    profileRequirement: {
      title,
    },
  });

  const handleNvFroalaBlur = () => saveComponent({
    content: froalaContent,
  });

  const redirectToEditCourseProfileQuestions = () => {
    window.location.href = `/${params.catalogId}/dashboard?tab=profiles&editor-component=${props.lectureComponent.id}`;
  };

  /**
   * Since this effect is only meant to be executed on component mounting, we're
   * not passing any dependency.
   */
  React.useEffect(() => {
    setSharedProps({
      ...sharedProps,
      extraOptions: {
        mode: 'prepend',
        renderOnMount: true,
        options: [
          sharedProps?.extraOptions?.getEditOption(
            t.LECTURE_PAGES.COMPONENTS.PROFILE_COMPLETION.SELECT_REQUIRED_QUESTIONS(),
            currentCourse.isContentManagementCollection,
            {
              disabledTooltip: t.LECTURE_PAGES.COMPONENTS.DROPDOWN.SETUP_IN_LINKED_LESSON_TOOLTIP(),
              tooltipPlacement: 'left',
              tooltipTextAlign: TextAlign.LEFT,
            },
          ),
          {
            type: 'text',
            callback: redirectToEditCourseProfileQuestions,
            text: t.LECTURE_PAGES.COMPONENTS.PROFILE_COMPLETION.EDIT_COURSE_PROFILE_QUESTIONS(),
            disabled: currentCourse.isContentManagementCollection,
            tooltip: {
              text: t.LECTURE_PAGES.COMPONENTS.DROPDOWN.SETUP_IN_LINKED_LESSON_TOOLTIP(),
              enabled: currentCourse.isContentManagementCollection,
              placement: 'left',
              offset: 20,
              textAlign: TextAlign.LEFT,
            },
          },
        ],
      },
    });

    const { lectureComponentToEdit } = $state.params;
    if (lectureComponentToEdit
      && props.lectureComponent.id === lectureComponentToEdit
    ) {
      /**
       * Opening edit modal if user was redirected back to lesson from course
       * admin dashboard (because user wanted to modify course profile
       * questions).
       */

      setTimeout(() => {
        sharedProps.openBasicModal();
      });
    }
  }, []);


  const isCompleted = profileRequirement.progress === 'completed';

  const renderTitleInput = () => (
    <LectureComponentHeaderInput
      value={title}
      editable={isEditMode}
      onBlur={handleTitleBlur}
      onChange={handleTitleInputChange}
      required
    />
  );

  const renderProfileCard = () => (
    <NvProfileCard
      user={currentUser}
      onViewProfile={openLearnerModal}
      anchorElementExtraProps={{
        'pendo-tag-name': config.config.pendo.profileActivity.viewProfile,
      }}
    />
  );

  return (
    <div id={`lecture-component-${lectureComponent?.id}`} css={styles}>
      {isActivity && (
        <React.Fragment>
          <div className='activity-status'>
            <ActivityStatus icon='profile' status={profileRequirement.progress} />
          </div>
          <ActivityTitle status={profileRequirement.progress} />
        </React.Fragment>
      )}
      <div className='title-input-wrapper'>
        {renderTitleInput()}
      </div>
      {isActivity && pointsConfiguration && !!pointsConfiguration.points && (
        <div className='activity-points'>
          <ActivityPoints pointsReceived={profileRequirement.pointsReceived} totalPoints={pointsConfiguration.points} />
        </div>
      )}
      {isNotViewMode ? (
        <div className='expectations-wrapper'>
          <NvFroala
            value={froalaContent}
            immediateReactModelUpdate
            onChange={setFroalaContent}
            onBlur={handleNvFroalaBlur}
            preset={FroalaViewMode.INLINE}
            placeholder={t.LECTURE_PAGES.COMPONENTS.EXERCISE.ADD_EXPECTATIONS()}
          />
        </div>
      ) : (
        !!froalaContent && (
        <div
          className='expectations-wrapper'
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: froalaContent }}
        />
        )
      )}

      {!currentCourse.isContentManagementCollection
        && (isActivity ? (
          <React.Fragment>
            {isCompleted ? renderProfileCard() : (
              <div className='cta'>
                <div className='note text-small'>
                  {t.LECTURE_PAGES.COMPONENTS.PROFILE_COMPLETION.NOTE(isNotViewMode.toString())}
                </div>
                <Button
                  size='sm'
                  disabled={isNotViewMode}
                  onClick={openLearnerModal}
                  pendo-tag-name={config.config.pendo.profileActivity.completeYourProfileButton}
                >
                  {t.LECTURE_PAGES.COMPONENTS.PROFILE_COMPLETION.COMPLETE_PROFILE()}
                </Button>
              </div>
            )}
          </React.Fragment>
        ) : renderProfileCard())}

      {currentCourse.isContentManagementCollection
        && (
          <div className='collection-warning mt-6'>
            <p className='mt-6 text-center'>{t.LECTURE_PAGES.COMPONENTS.PROFILE_COMPLETION.PROFILE_COMPLETION_WARNING()}</p>
          </div>
        )}
    </div>
  );
};

export default ProfileCompletionLectureComponent;
